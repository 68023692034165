pre {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  white-space: pre-wrap;

  @include responsive-margin(4, "bottom");
  @include responsive-padding(2);

  background: color("light-gray");
}

kbd,
code {
	color: color("magenta--accessible");
	@include font($size: 16);

	margin-top: 0;
	@include responsive-margin(4, "bottom");

	font-family: $font-family-monospace;
}