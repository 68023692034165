.radios {
	padding-bottom: spacing(5);
}

.radios--item {
	@include font($size: 19);

	display: block;
	position: relative;

	min-height: $radios-size;

	margin-bottom: spacing(2);
	padding-left: $radios-size;

	clear: left;
}

.radios--item:last-child,
.radios--item:last-of-type {
	margin-bottom: 0;
}

.radios--input {
	cursor: pointer;


	position: absolute;

	z-index: 1;
	top: $radios--input-offset * -1;
	left: $radios--input-offset * -1;

	width: $touch-target-size;
	height: $touch-target-size;
	margin: 0;

	opacity: 0;
}

.radios--label {
	display: inline-block;
	margin-bottom: 0;
	padding: 8px spacing(3) spacing(1);
	cursor: pointer;
	// remove 300ms pause on mobile
	touch-action: manipulation;
}

// ( ) Radio ring
.radios--label:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;

	width: $radios-size;
	height: $radios-size;

	border: $border-width-form-element solid currentColor;
	border-radius: 50%;
	background: transparent;
}

//  •  Radio button
//
// We create the 'button' entirely out of 'border' so that they remain
// 'filled' even when colors are overridden in the browser.
.radios--label:after {
	content: "";

	position: absolute;
	top: spacing(2);
	left: spacing(2);

	width: 0;
	height: 0;

	border: spacing(2) solid $radios-button--color;
	border-radius: 50%;
	opacity: 0;
	background: $radios-button--color;
}

.radios--hint {
	display: block;
	padding-right: spacing(3);
	padding-left: spacing(3);
}

// Focused state
.radios--input:focus + .radios--label:before {
	border-width: 4px;

	// When colors are overridden, the yellow box-shadow becomes invisible
	// which means the focus state is less obvious. By adding a transparent
	// outline, which becomes solid (text-colored) in that context, we ensure
	// the focus remains clearly visible.
	outline: $focus-width solid transparent;
	outline-offset: 1px;

	// When in an explicit forced-color mode, we can use the Highlight system
	// color for the outline to better match focus states of native controls
	@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	  outline-color: Highlight;
	}

	box-shadow: 0 0 0 $radios-focus-width $radios-color--focus;
}

// Selected state
.radios--input:checked + .radios--label:after {
	opacity: 1;
}

// Disabled state
.radios--input:disabled,
.radios--input:disabled + .radios--label {
	cursor: default;
}

.radios--input:disabled + .radios--label {
	opacity: .5;
}

// =========================================================
// Inline radios
// =========================================================

.radios--inline {
	@include media-query ($from: tablet) {
		@include clearfix;

		.radios--item {
			margin-right: spacing(4);
			float: left;
			clear: none;
		}
	}

	// Prevent inline modifier being used with conditional reveals
	&.radios--conditional {
		.radios--item {
			margin-right: 0;
			float: none;
		}
	}
}