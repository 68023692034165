.article--event .page--content {
	border-top: none !important;
}

.section--event-details {
	padding: spacing(5);
	background: color("magenta--light");

	p:last-child {
		margin-bottom: 0;
	}
}

.event--event-details--label {
	margin-bottom: spacing(1);
}

.event--event-details--value {
	&:last-child {
		margin-bottom: 0;
	}
}

.section--event-registration {
	padding: spacing(5);
	background: color("white");
	border: 3px solid color("magenta--light");

	p:last-child {
		margin-bottom: 0;
	}
}

.event--aside {
	margin: spacing(6) 0;
	padding: spacing(4) spacing(4) spacing(2);
	background: color("magenta--light");

	details:last-of-type {
		border-bottom: 0;
	}
}