html {
	// Set the overall page background color to the same color as used by the
	// footer to give the illusion of a long footer.
	background-color: $html--background-color;

	// Prevent automatic text sizing, as we already cater for small devices and
	// would like the browser to stay on 100% text zoom by default.
	text-size-adjust: 100%;

	// Force the scrollbar to always display in IE, to prevent horizontal page
	// jumps as content height changes (e.g. autocomplete results open).
	@include media-query($media-type: screen) {
		overflow-y: scroll;
	}
}

body {
	// The default margins set by user-agents are not required since we have our
	// own containers.
	margin: 0;
	// Set the overall body of the page back to the typical background color.
	background-color: $body--background-color;
}