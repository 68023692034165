.article--consultation .page--content {
	border-top: none !important;
}

.section--consultation-details {
	padding: spacing(5);
	background: lighten(color("magenta"), 50%);

	p:last-child {
		margin-bottom: 0;
	}
}

.consultation--consultation-details--label {
	margin-bottom: spacing(1);
}

.consultation--consultation-details--value {
	&:last-child {
		margin-bottom: 0;
	}
}

.section--consultation-start-buttons {
	padding: spacing(5);
	background: color("white");
	border: 3px solid color("magenta--light");

	p:last-child {
		margin-bottom: 0;
	}
}

.consultation--aside {
	padding-top: spacing(5);
	border-top: 3px solid color("light-gray");

	blockquote:first-of-type {
		margin-top: 0;
	}
}