.grid-row {
	@include clearfix;
	margin-right: - ($gutter-half);
	margin-left: - ($gutter-half);
}

@each $width in map-keys($grid-widths) {
	.grid-column-#{$width} {
		@include grid-column($width);
	}
}

// These *must* be defined in a separate loop as they have the same
// specificity as the non-breakpoint specific classes, so need to appear after
// them in the outputted CSS
@each $width in map-keys($grid-widths) {
	.grid-column-#{$width}-from-desktop {
		@include grid-column($width, $at: desktop);
	}
}

.width-container {
	@include width-container;
}

.main-wrapper {
	@include main-wrapper;

	@include media-query($media-type: print) {
		padding: 0;
	}
}

// Using the `.main-wrapper--auto-spacing` modifier should apply the
// correct spacing depending on whether there are any elements
// (such the back link, breadcrumbs or phase banner components) before the
// `.main-wrapper` in the `width-container`.
//
// If you need to control the spacing manually, use the
// `main-wrapper--lg` modifier instead.
.main-wrapper--auto-spacing:first-child,
.main-wrapper--lg {
	@include responsive-padding(8, "top");

	@include media-query($media-type: print) {
		padding-top: 0;
	}
}

.main-wrapper--no-top-padding {
	padding-top: 0 !important;
}