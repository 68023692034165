%list {
	@include font($size: 19);
	color: $text-color;
	margin-top: 0;
	@include responsive-margin(4, "bottom");
	padding-left: 0;
	list-style-type: none;

	// Add a top margin for nested lists
	%list {
		margin-top: spacing(2);
	}
}

%list--bullet {
	padding-left: spacing(7);
	list-style-type: disc;
}

%list--number {
	padding-left: spacing(8);
	list-style-type: decimal;
}

%list--no-styles {
	padding-left: 0;
	list-style-type: none;
}

ul,
ol {
	@extend %list;
}

ul > li,
ol > li {
	margin-bottom: spacing(1);
}

ul {
	@extend %list--bullet;
}

ol {
	@extend %list--number;
}

ul.list--no-styles,
ol.list--no-styles {
	@extend %list;
	@extend %list--no-styles;
}

.list--category {
	@extend %bold;
}

li {
	@extend %body-md;
}