#markdown-toc {
	@extend %list--no-styles;
}

.article--publication .page--content {
	border-top: none;
	padding-top: 0;
}

#markdown-toc {
	padding: spacing(5) 0;
	border-top: 3px solid color("magenta");
	border-bottom: 3px solid color("light-gray");
}

#markdown-toc li {
	margin-bottom: spacing(3);
}

#markdown-toc li:first-child {

	&::before {
		position: relative;
		display: block;
		@extend h3;
		content: "Contents";
	}
	@include media-query($media-type: print) {
		page-break-before: always;
		border-top: none !important;

		&:before {
			position: relative;
			display: block;

			content: "Contents";
			color: $text-color;
			@include font($size: 36, $weight: bold);

			display: block;

			margin-top: 0;
			@include responsive-margin(6, "bottom");
		}
	}
}

#markdown-toc ul {
	display: none;
}

#markdown-content {
	@extend .grid-column-two-thirds;
}

#markdown-content > *:first-child {
	border-top: 3px solid color("light-gray");
	padding-top: spacing(5);

	@include media-query($media-type: print) {
		page-break-before: always;
		border-top: none !important;
	}
}

#markdown-content + .footnotes {
	@extend .grid-column-full;
}