.page--header--loop .page--heading {
	@extend %heading-xxl;
	@include responsive-margin(5, "top");
	@include responsive-margin(5, "bottom");
}

.page--header--loop .page--excerpt {
	@extend %body-xl;
}

.loop--article-list {
	border-top: 5px solid color("magenta");
	padding: 0 0 spacing(6);
}

.loop--article-list--item {
	padding: spacing(4) 0;
	margin-bottom: 0;
	border-bottom: 1px solid color("light-gray");

	&:last-child {
		border-bottom: 0;
	}

	*:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.loop--article-list--item--title {
	color: $text-color;
	@include font($size: 24, $weight: bold);
	display: block;
	margin-top: 0;
	@include responsive-margin(2, "bottom");
}

.loop--article-list--item--meta,
.loop--article-list--item--excerpt {
	@extend %body-sm;
	margin-bottom: spacing(2);
}

.loop--article-list--item--meta {
	color: color("dark-gray");
}