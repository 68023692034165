// Modifiers that make labels look more like their equivalent headings

%label-xl {
	@include font($size: 48, $weight: bold);
	margin-bottom: spacing(3);
}

%label-lg {
	@include font($size: 36, $weight: bold);
	margin-bottom: spacing(3);
}

%label-md {
	@include font($size: 24, $weight: bold);
	margin-bottom: spacing(2);
}

%label-sm {
	@include font($size: 19, $weight: bold);
}

.label-xl {
	@extend %label-xl;
}
.label-lg {
	@extend %label-lg;
}
.label-md {
	@extend %label-md;
}
.label-sm {
	@extend %label-sm;
}


label,
.label {
	@extend %body-md;
	display: block;
}

.input-label {
	margin-top: spacing(4);
	margin-bottom: spacing(2);
}

.inline-label {
	margin-top: spacing(4);
	margin-bottom: spacing(2);

	@include media-query($from: tablet) {
		min-height: 40px;
		line-height: 40px;
		vertical-align: middle;
		margin: 0;
	}
}

.required,
.asterisk {
	color: color("dark-gray");
}

.hint {
	@extend %body-md;
	display: block;

	margin-bottom: spacing(3);
}

// When the label is nested inside a heading, override the heading so that it
// does not have a margin. Effectively we want to be able to treat the heading
// as if it is not there.
//
// This breaks BEM conventions because it exists as a parent of the 'block',
// so we can't really consider an element.
h1 > label,
h2 > label,
h3 > label,
h4 > label {
	margin: 0;
}
