// Paragraph text
// @access public
%body-xl {
	color: $text-color;
	@include font($size: 32);

	margin-top: 0;
	@include responsive-margin(8, "bottom");
}

%body-lg {
	color: $text-color;
	@include font($size: 24);

	margin-top: 0;
	@include responsive-margin(6, "bottom");
}

%body-md {
	color: $text-color;
	@include font($size: 19);

	margin-top: 0;
	@include responsive-margin(4, "bottom");
}

%body-sm {
	color: $text-color;
	@include font($size: 16);

	margin-top: 0;
	@include responsive-margin(4, "bottom");
}

%bold {
	font-weight: $font-weight-bold;
}

%italic {
	font-style: italic;
}

p {
	@extend %body-md;
}

.lead {
	@extend %body-lg;
}

.small {
	@extend %body-sm;
}

strong,
b {
	@extend %bold;
}

em,
i {
	@extend %italic;
}