@mixin typography-common {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/// Responsive typography helper

@mixin typography-responsive($size, $override-line-height: false, $important: false) {

  @if not map-has-key($typography-scale, $size) {
    @error "Unknown font size `#{$size}` - expected a point from the typography scale.";
  }

  $font-map: map-get($typography-scale, $size);

  @each $breakpoint, $breakpoint-map in $font-map {
    $font-size: map-get($breakpoint-map, "font-size");
    $line-height: map-get($breakpoint-map, "line-height");

    @if $breakpoint == null {
      font-size: $font-size;
      line-height: $line-height;
    } @else if $breakpoint == "print" {
      @include media-query($media-type: print) {
        font-size: $font-size;
        line-height: $line-height;
      }
    } @else {
      @include media-query($from: $breakpoint) {
        font-size: $font-size;
        line-height: $line-height;
      }
    }
  }
}

/// Font helper

@mixin font($size, $weight: regular, $line-height: false) {

  @include typography-common;
  // @include media-query($media-type: print) {
  //   font-family: $font-family-print;
  // }

  @if $weight == regular {
      font-weight: $font-weight-regular
  } @else if $weight == bold {
      font-weight: $font-weight-bold
  }

  @if $size {
  @include typography-responsive($size, $override-line-height: $line-height);
  }
}