.input {
	@include font($size: 19);

	box-sizing: border-box;
	width: 100%;
	height: 40px;
	margin-top: 0;

	padding: spacing(1);
	// setting any background-color makes text invisible when changing colors to dark backgrounds in Firefox (https://bugzilla.mozilla.org/show_bug.cgi?id=1335476)
	// as background-color and color need to always be set together, color should not be set either
	border: $border-width-form-element solid $input-border-color;
	border-radius: 0;

	// Disable inner shadow and remove rounded corners
	appearance: none;

	&:focus {
		outline: $focus-width solid $input-focus-color;
		// Ensure outline appears outside of the element
		outline-offset: 0;
		// Double the border by adding its width again. Use `box-shadow` for this // instead of changing `border-width` - this is for consistency with
		// components such as textarea where we avoid changing `border-width` as
		// it will change the element size. Also, `outline` cannot be utilised
		// here as it is already used for the yellow focus state.
		box-shadow: inset 0 0 0 $border-width-form-element;
	}
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

.input[type="number"] {
	-moz-appearance: textfield;
}

input:invalid,
.input--error {
	border: $border-width-form-element-error solid $input-error-color;
	outline: 3px solid color("red");
	outline-offset: 1px;

	&:focus {
		border-color: $input-border-color;
	}
}

// The ex measurements are based on the number of W's that can fit inside the input
// Extra space is left on the right hand side to allow for the Safari prefill icon
// Linear regression estimation based on visual tests: y = 1.76 + 1.81x

%input--width-30 {
	max-width: 56ex + 3ex;
}

%input--width-20 {
	max-width: 38ex + 3ex;
}

%input--width-10 {
	max-width: 20ex + 3ex;
}

%input--width-5 {
	max-width: 10.8ex;
}

%input--width-4 {
	max-width: 9ex;
}

%input--width-3 {
	max-width: 7.2ex;
}

%input--width-2 {
	max-width: 5.4ex;
}

.input--width-30 {
	@extend %input--width-30
}

.input--width-20 {
	@extend %input--width-20
}

.input--width-10 {
	@extend %input--width-10
}

.input--width-5 {
	@extend %input--width-5
}

.input--width-4 {
	@extend %input--width-4
}

.input--width-3 {
	@extend %input--width-3
}

.input--width-2 {
	@extend %input--width-2
}


.input--wrapper {
	display: flex;

	.input {
		flex: 0 1 auto;
	}

	.input:focus {
		// Hack to stop focus style being overlapped by the suffix
		z-index: 1;
	}

	// Split prefix/suffix onto separate lines on narrow screens
	@include media-query($until: mobile) {
		display: block;

		.input {
			// Set max-width to override potential width override class on the input
			max-width: 100%;
		}
	}
}

.input--prefix,
.input--suffix {
	@include font($size: 19);

	box-sizing: border-box;
	display: inline-block;
	min-width: 40px;
	height: 40px;

	padding: spacing(1);
	border: $border-width-form-element solid $input-border-color;
	background-color: color("light-gray");

	text-align: center;
	@include media-query($until: tablet) {
		line-height: 1.6;
	}
	white-space: nowrap;

	// Emphasise non-editable status of prefixes and suffixes
	cursor: default;

	flex: 0 0 auto;

	// Split prefix/suffix onto separate lines on narrow screens
	@include media-query($until: mobile) {
		display: block;
		height: 100%;
		white-space: normal;
	}
}

.input--prefix {
	@include media-query($until: mobile) {
		border-bottom: 0;
	}
	@include media-query($from: mobile) {
		border-right: 0;
	}

	// Split prefix/suffix onto separate lines on narrow screens
	.input--suffix {
		@include media-query($until: mobile) {
		  border-top: 0;
		}
		@include media-query($from: mobile) {
			border-left: 0;
		}
	}
}