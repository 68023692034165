textarea {
	@include font($size: 19, $line-height: 1.25);

	box-sizing: border-box; // should this be global?
	display: block;
	width: 100%;
	min-height: 40px;
	@include responsive-margin(6, "bottom");
	padding: spacing(1);

	resize: vertical;

	border: $border-width-form-element solid $input-border-color;
	border-radius: 0;

	-webkit-appearance: none;

	&:focus {
		outline: $focus-width solid $input-focus-color;
		// Ensure outline appears outside of the element
		outline-offset: 0;
		// Double the border by adding its width again. Use `box-shadow` to do
		// this instead of changing `border-width` (which changes element size) and
		// since `outline` is already used for the yellow focus state.
		box-shadow: inset 0 0 0 $border-width-form-element;
	}
}

.textarea--error {
	border: $border-width-form-element-error solid $input-error-color;

	&:focus {
		border-color: $input-border-color;
	}
}