// Headings

%heading-xxl {
	color: $text-color;
	@include font($size: 60, $weight: bold);

	display: block;

	margin-top: 0;
	@include responsive-margin(8, "bottom");
}

%heading-xl {
	color: $text-color;
	@include font($size: 48, $weight: bold);

	display: block;

	margin-top: 0;
	@include responsive-margin(8, "bottom");
}

%heading-lg {
	color: $text-color;
	@include font($size: 36, $weight: bold);

	display: block;

	margin-top: 0;
	@include responsive-margin(6, "bottom");
}

%heading-md {
	color: $text-color;
	@include font($size: 24, $weight: bold);

	display: block;

	margin-top: 0;
	@include responsive-margin(4, "bottom");
}

%heading-sm {
	color: $text-color;
	@include font($size: 19, $weight: bold);

	display: block;

	margin-top: 0;
	@include responsive-margin(4, "bottom");
}

h1,
.heading-xl {
	@extend %heading-xl;
}

h2,
.heading-lg {
	@extend %heading-lg;
}

h3,
.heading-md {
	@extend %heading-md;
}

h4,
.heading-sm {
	@extend %heading-sm;
}

// Captions to be used inside headings

%caption-xl {
	@include font($size: 27);

	@include media-query($media-type: print) {
		@include font($size: 36, $weight: bold);
	}

	display: block;

	margin-bottom: spacing(1);

	color: $secondary-text-color;
}

%caption-lg {
	@include font($size: 24);

	display: block;

	margin-bottom: spacing(1);
	color: $secondary-text-color;

	@include media-query($from: tablet) {
		margin-bottom: 0;
	}
}

%caption-md {
	@include font($size: 19);

	display: block;

	color: $secondary-text-color;
}

.caption-xl {
	@extend %caption-xl;
}

.caption-lg {
	@extend %caption-lg;
}

.caption-md {
	@extend %caption-md;
}