.admin--link-block {
  display: block;
	padding: spacing(5) spacing(3) spacing(1) spacing(3);
	@include media-query($from: tablet) {
		padding: spacing(5) spacing(5) 0 spacing(5);
	}
	background: color("magenta--light");
	margin-bottom: spacing(5);
	border-top: 5px solid color("magenta");
}

.admin--link-block--guidance {
	background: lighten(color("cyan"), 50%);
	border-top: 5px solid color("cyan");
}

.admin--step-by-step,
.admin--select {
	@include clearfix;
	padding-bottom: spacing(10);
}

.admin--step-by-step h2,
.admin--select h2 {
	border-top: 5px solid color("magenta");
	padding-top: spacing(5);
}


.fieldset-row {
	padding-bottom: spacing(5);
	margin-bottom: spacing(5);
}

.fieldset--post-github,
.fieldset--email-notify {
	padding: spacing(5) 0 0;
	border-top: 5px solid color("light-gray");
}

.folder-navigation {
	background: color("white");
	border: 3px solid color("magenta--light");
}

.folder-navigation .radios {
	padding: spacing(3);
}

.folder-name {
	z-index: 999999;
	position: sticky;
	position: -webkit-sticky;
	top: 0;

	background: color("magenta--light");
	padding: spacing(3);
}

.folder-name .button {
	margin-bottom: 0;
}

.revealed-input {
	padding: spacing(3);
	margin-left: spacing(9);
	margin-bottom: spacing(5);
	background: color("magenta--light");
}