a {
	@include link-common;
	@include link-style-default;
	@include link-print-friendly;
}

// Variant classes should always be used in conjunction with the .link
// class, so we do not need the common link styles as they will be inherited.

.link--text-color {
	@include link-style-text;
}

.link--inverse {
	@include link-style-inverse;
}

.link--no-underline {
	@include link-style-no-underline;
}

.link--no-visited-state {
	@include link-style-no-visited-state;
}

