table {
	@include font($size: 19);
	color: $text-color;
	width: 100%;
	@include responsive-margin(6, "bottom");

	border-spacing: 0;
	border-collapse: collapse;
}

thead {
	border-bottom: 2px solid $border-color;
}
tr {
	border-bottom: 1px solid $border-color;

	&:last-child {
		border-bottom: none;
	}
}

tfoot {
	border-top: 2px solid $border-color;
}

th {
	@extend %bold;
	padding: spacing(2) spacing(4) spacing(2) 0;
	text-align: left;
	vertical-align: top;
}

td {
	padding: spacing(2) spacing(4) spacing(2) 0;
	text-align: left;
	vertical-align: top;
}

th:last-child,
td:last-child {
	padding-right: 0;
}

caption {
	@extend %body-sm;
	@extend %bold;

	display: table-caption;
	text-align: left;
}

// Modifiers that make captions look more like their equivalent headings

.caption--xl {
	@extend %heading-xl;
	margin-bottom: spacing(3);
}

.caption--lg {
	@extend %heading-lg;
	margin-bottom: spacing(3);
}

.caption--md {
	@extend %heading-md;
	margin-bottom: spacing(3);
}

.caption--sm {
	@extend %heading-sm;
}