%fieldset {
	min-width: 0;
	margin: 0;
	padding: 0;
	border: 0;
	@include clearfix;
}

fieldset,
.fieldset {
	@extend %fieldset;

	+ fieldset {
		@include responsive-margin(10, "top");
	}
}

// Fix for Firefox < 53
// https://bugzilla.mozilla.org/show_bug.cgi?id=504622
@supports not (caret-color: auto) {
	.fieldset,
	x:-moz-any-link { // stylelint-disable-line selector-type-no-unknown
	  display: table-cell;
	}
}

legend {
	@extend %heading-md;

	// Fix legend text wrapping in Edge and IE
	// 1. IE9-11 & Edge 12-13
	// 2. IE8-11
	box-sizing: border-box; // 1
	display: table;         // 2
	max-width: 100%;        // 1
	padding: 0;

	white-space: normal;    // 1
}

// Modifiers that make legends look more like their equivalent headings

.legend-xl {
	@extend %heading-xl;
	margin-bottom: spacing(3);
}

.legend-lg {
	@extend %heading-lg;
	margin-bottom: spacing(3);
}

.legend-md {
	@extend %heading-md;
	margin-bottom: spacing(3);
}

.legend-sm {
	@extend %heading-sm;
}

// When the legend contains an H1, we want the H1 to inherit all styles from
// the legend. Effectively we want to be able to treat the heading as if it is
// not there.
h1 > legend,
h2 > legend,
h3 > legend,
h4 > legend {
	margin: 0;
	font-size: inherit;
	font-weight: inherit;
}

.form-row {
	margin-bottom: spacing(2);
}