.toolbar {
	position: sticky;
	z-index: 999999;

	bottom: 0;
	top: auto;
	left: 0;
	right: 0;

	padding: spacing(3) 0;

	background: $toolbar--background-color;

	@include clearfix();
}

.dialog {
	z-index: 9999999;
	padding: 0;
	min-width: 50vh;
	border: 0;
	box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.dialog::backdrop {
	background: rgba(0, 0, 0, 0.7);
}

.dialog--title {
	margin: 0;
}

.button--close {
	float: right;
}

.button--dialog,
.button--toolbar {
	margin: 0 !important;
}

.dialog--header,
.dialog--content,
.dialog--actions {
	padding: spacing(3);
	margin: 0;
	@include clearfix();
}

.dialog--content--center {
	text-align: center;
}

.dialog--header,
.dialog--actions {
	background: color('magenta--light');
}