// =========================================================
// Helpers
// =========================================================

/// Media Query

@mixin media-query($from: null, $until: null, $media-type: "screen", $and: null) {

    @if $from == "null" {

        @content

    } @else if $and {

        @media only screen and #{$and} {
            @content
        }

    } @else if $from == "tablet" {

        @media only screen and (min-width: map-get($breakpoints, "tablet")) {
            @content
        }

    } @else if $from == "desktop" {

        @media only screen and (min-width: map-get($breakpoints, "desktop")) {
            @content
        }

    } @else if $until == "tablet" {

        @media only screen and (max-width: map-get($breakpoints, "tablet")) {
            @content
        }

    } @else if $until == "desktop" {

        @media only screen and (max-width: map-get($breakpoints, "desktop")) {
            @content
        }

    } @else if $media-type == "screen" {

        @media only screen {
            @content
        }

    } @else if $media-type == "print" {

        @media only print {
            @content
        }

    }

}