footer,
.footer {
	@include font($size: 16);
	@include responsive-padding(7, "top");
	@include responsive-padding(5, "bottom");

	// border-top: 5px solid $footer--border-color;
	color: $footer--text-color;
	background: $footer--background-color;

	@include media-query($media-type: print) {
		display: none;
	}
}

footer a,
.footer a,
.footer--link {
	@include typography-common;
	@extend %body-sm;

	text-decoration: none;

	&:hover {
	  text-decoration: underline;
	  text-decoration-thickness: 3px;
	  text-underline-offset: spacing(1);
	}

	&:focus {
		@include focused-text;
	}
 }

.footer--section-break {
	margin: 0; // Reset `<hr>` default margins
	@include responsive-margin(6, "top");
	@include responsive-margin(6, "bottom");
	border: 0; // Reset `<hr>` default borders
	border-bottom: 3px solid $footer--border-color;
}

.footer--meta p {
	@include font($size: 16);
	margin-bottom: spacing(2);
	color: $footer--text-color;
}

.footer--meta-item--grow {
	flex: 1; // Support: Flexbox
	@include media-query ($until: tablet) {
		flex-basis: 320px; // Support: Flexbox
	}
}

.footer--heading {
	@extend %heading-sm;
	color: $footer--text-color;
	@include responsive-margin(4, "bottom");
	padding-top: spacing(3);
	border-top: 3px solid $footer--border-color;
}

.footer--list {
	margin: 0;
	margin-bottom: spacing(5);
	padding: 0;
	list-style: none;
	column-gap: $gutter; // Support: Columns
	vertical-align: top;
}

.footer--list-item {
	@include responsive-margin(1, "bottom");
}

.footer--list-item:last-child {
	margin-bottom: 0;
}

.feedback {
	margin-bottom: spacing(3);

	padding: spacing(2);
	background: color("magenta--light");

	@include media-query($media-type: print) {
		display: none;
	}
}