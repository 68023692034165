.breadcrumbs {
	@include typography-responsive($size: 16);
	color: $text-color;

	margin-top: spacing(3);
	margin-bottom: spacing(2);

	@include media-query($from: tablet) {
		padding-bottom: spacing(2);
		border-bottom: 1px solid $border-color;
	}

	@include media-query($media-type: print) {
		display: none;
	}
}

.breadcrumbs--list {
	@include clearfix;

	margin: 0;
	padding: 0;
	list-style-type: none;
}

.breadcrumbs--list-item {

	display: inline-block;
	position: relative;

	@include typography-responsive($size: 16);
	margin-bottom: spacing(1);

	// Add both margin and padding such that the chevron appears centrally
	// between each breadcrumb item
	margin-left: spacing(2);
	padding-left: spacing(2) + $chevron-altitude-calculated;

	float: left;

	// Create a chevron using a box with borders on two sides, rotated 45deg.
	&:before {
		content: "";
		display: block;

		position: absolute;

		top: 0;
		bottom: 0;

		// Offset by the difference between the width of the non-rotated square
		// and its width when rotated
		left: (($chevron-altitude-calculated * -2) + $chevron-size + $chevron-border-width);

		width: $chevron-size;
		height: $chevron-size;

		margin: auto 0;

		transform: rotate(45deg);

		border: solid;
		border-width: $chevron-border-width $chevron-border-width 0 0;
		border-color: $chevron-border-color;
	}

	&:first-child {
		margin-left: 0;
		padding-left: 0;

		&:before {
			content: none;
			display: none;
		}
	}
}

.breadcrumbs--link {
	@include link-common;
	@include link-style-text;
}

.breadcrumbs--collapse-on-mobile {
	@include media-query($until: tablet) {
		.breadcrumbs--list-item {
			display: none;

			&:first-child,
			&:last-child {
				display: inline-block;
			}

			&:before {
				top: 6px;
				margin: 0;
			}
		}

		.breadcrumbs--list {
			display: flex;
		}
	}
}