.page--header--collection--left-nav {

}

.collection--left-nav--navigation,
.collection--left-nav--article {
	padding: spacing(5) 0;
	margin-bottom: 0 !important;
}

.collection--left-nav--navigation {
	border-top: 3px solid color("magenta");
}

.collection--left-nav--article {
	border-top: 3px solid color("gray");
}