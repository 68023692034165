blockquote {
	@include font($size: 19);
	color: $text-color;
	padding: spacing(1) 0 spacing(1) spacing(3);
	// Margin top intended to collapse
	// This adds an additional 10px to the paragraph above
	@include responsive-margin(6, "top");
	@include responsive-margin(6, "bottom");

	clear: both;

	border-left: $border-width-wide solid color("magenta");
	> :first-child {
		margin-top: 0;
	}

	> :only-child,
	> :last-child {
		margin-bottom: 0;
	}
}