// Images

/// Images are always full width

img {
    width: 100%;
    margin: spacing(5) 0;
}

.hero-image {
    margin-top: 0;
}
.hero-video {
    aspect-ratio: 2 / 1;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 0;
}

.topic--banner--image {
    aspect-ratio: 4/1;
    @include media-query($until: tablet) {
        aspect-ratio: 2/1;
    }
    object-fit: cover;
    object-position: 50% 50%;
}
