.page--header {
	@include clearfix;

	@include media-query($media-type: print) {
		margin: 0;
		padding: 0 1cm;
		width:100%;
		height:99%;
		page-break-before: always;
		page-break-after: always;
		background: color("magenta--accessible");
		box-sizing: border-box;
	}
}

.page--divider {
	@include clearfix;

	@include media-query($media-type: print) {
		@include font($size: 60, $weight: bold);
		margin: 0;
		padding: 7cm 1cm 0;
		width:100%;
		height:99%;
		page-break-before: always;
		page-break-after: always;
		background: color("magenta--light");
		color: color("magenta--accessible");
		box-sizing: border-box;

		&:before {
			position: relative;
			display: block;

			top: -5cm;

			@include font($size: 36, $weight: bold);

			content: "Civil Service \A LGBT+ Network.";
			white-space:pre;

			color: color("magenta--accessible") !important;
		}
	}
}

.page--caption {
	@extend %caption-xl;

	@include media-query($media-type: print) {
		padding: 7cm 0 0;
		color: color("white");
		font-weight: bold;

		&:before {
			position: absolute;
			display: block;

			top: 2cm;

			content: "Civil Service \A LGBT+ Network.";
			white-space:pre;

			color: color("magenta--light") !important;
		}
	}

}

.page--heading {
	@extend %heading-xxl;
	@include responsive-margin(3, "top");
	@include responsive-margin(7, "bottom");
	max-width: 80%;

	@include media-query($media-type: print) {
		color: color("white");
		font-weight: bold;

		margin-top: 0;
		padding-top: 40%;
	}
}

.page--caption + .page--heading {

	@include media-query($media-type: print) {

		padding: 0;

	}

}

.page--excerpt {
	@extend %body-lg;
	@include responsive-margin(8, "bottom");

	@include media-query($media-type: print) {
		color: color("white");
		max-width: 40ch;
	}
}

.page--meta-info {
	@include responsive-padding(3, "top");
	@include responsive-margin(7, "bottom");

	border-top: 3px solid color("light-gray");
	@include media-query($media-type: print) {
		border-top: none;
	}
	@include clearfix();
}

.page--meta-info--list {
	@include clearfix();
}

.page--meta-info--list--with-image {
	display: inline-block;
}

.page--meta-info--term,
.page--meta-info--definition {
	position: relative;
	margin-bottom: spacing(1);
	@include media-query($from: tablet) {
		display: inline-block;
		float: left;
		min-width: 10ch;
		margin-bottom: spacing(1);
	}
}

.page--meta-info--term {
	@include media-query($from: tablet) {
		margin-right: spacing(5);
		clear: left;
	}

	@include media-query($media-type: print) {
		display: none;
	}
}

.page--meta-info--definition {
	@include media-query($until: tablet) {
		margin-bottom: spacing(3);
	}
	@include media-query($from: tablet) {
		clear: right;
	}
	@include media-query($media-type: print) {
		color: color("white");
		font-weight: bold;
		max-width: 40ch;
	}
}

.volunteer--author {
	float: left;
	box-sizing: border-box;
	margin: 0 spacing(5) 0 0;
}

.page--meta-info--team-member {

	@include media-query($media-type: print) {

		a[href^="/"],
		a[href^="http://"],
		a[href^="https://"] {
			&:after {
				content: "";
			}
		}

		a:link,
		a:visited {
			color: color("white");
		}

	}

}

.page--content {
	border-top: 3px solid color("light-gray");
}

.page--aside {
	border-top: 3px solid color("magenta");
	@include media-query($media-type: print) {
		display: none;
	}
}

.page--content,
.page--aside {
	padding: spacing(5) 0;
}