blockquote.document-box {

position: relative;
	@include font($size: 19);
	color: $text-color;
	padding: spacing(2) spacing(4) spacing(2) 0;
	// Margin top intended to collapse
	// This adds an additional 10px to the paragraph above
	@include responsive-margin(6, "top");
	@include responsive-margin(6, "bottom");

	clear: both;

	border: none;
	background: color("magenta--light");
	> :first-child {
		margin-top: spacing(2);
	}

	> :only-child,
	> :last-child {
		margin-bottom: spacing(2);
	}
	> * {

		@include media-query($from: tablet) {
			padding-left: spacing(10) + spacing(2);
		}
		@include media-query($until: tablet) {
			padding-left: spacing(9) + spacing(2);
		}
	}

	p:first-child {
		@extend .label-sm;
	}

	&:before {
		content: "";
		position: relative;
		top: spacing(2);
		left: spacing(3);
		float: left;
		display: block;
		padding: spacing(2);

		border-radius: 100%;
		background: color("magenta");
		background-position: center;
		background-repeat: no-repeat;
		color: color("white");


		@include media-query($from: tablet) {
			background-size: 30px 30px;
			width: 30px;
			height: 30px;
		}
		@include media-query($until: tablet) {
			background-size: 20px 20px;
			width: 20px;
			height: 20px;
		}

		background-image: url("data:image/svg+xml,%3Csvg xmlns:xl='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' xmlns:dc='http://purl.org/dc/elements/1.1/' version='1.1' viewBox='0 0 30 30' width='30' height='30'%3E%3Cdefs/%3E%3Cmetadata%3E Produced by OmniGraffle 7.19.1%5Cn2021-11-13 18:31:56 +0000%3C/metadata%3E%3Cg id='doc' stroke-dasharray='none' fill='none' stroke='none' stroke-opacity='1' fill-opacity='1'%3E%3Ctitle%3Edoc%3C/title%3E%3Cg id='doc_Layer_1'%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='Graphic_5'%3E%3Cpath d='M 5 1 L 17 1 L 17 10 L 17 1 L 25 10 L 17 10 L 25 10 L 25 29 L 5 29 Z' fill='none'/%3E%3Cpath d='M 5 1 L 17 1 L 17 10 L 17 1 L 25 10 L 17 10 L 25 10 L 25 29 L 5 29 Z' stroke='%23FFED00' stroke-linecap='butt' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");


	}
	@include clearfix();

}